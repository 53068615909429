/*
 * @Descripttion: $0
 * @version: 
 * @Author: linPing
 * @Date: 2021-11-12 10:37:38
 * @LastEditTime: 2021-11-12 11:18:27
 */
/*
 * @Descripttion: $0
 * @version:
 * @Author: linPing
 * @Date: 2021-09-03 15:23:41
 * @LastEditTime: 2021-11-12 10:46:50
 */
/**
 *  项目的配置文件
 */

export const apiUrl = 'https://admin-dev.aikangcloud.com/'//接口请求地址
export const chatUrl = 'wss://im-dev.aikangcloud.com/'//客服地址
export const storeUrl = 'https://seller-dev.aikangcloud.com/';//对应的商户后台地址
export const curLang = 'zh';//当前语言,zh:中文，若为其他语言，需要对应/src/assets/language下面的文件名

/** copyright *** slodon *** version-v2.9 *** date-2021-08-31 ***主版本v3.2**/


